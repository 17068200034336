import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import "../../App.css";
import "../../style.scss";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import WhatsAppButton from '../../Components/WhatsAppButton';

import plane from "../../assets/images/small-plane.png";

function Atp() {
    const { t } = useTranslation();
    return (
        <div className='main-con-ppl'>
            <Header />
            <h1 className='ppl-head'>{t("atp_info_header")}</h1>
            <div className='ppl-exp'>
                <div className='exp-img-con'>
                    <img src={plane} alt="uçak" />
                </div>
                <div className='exp-main-con'>
                    <br /><br />
                    <p>{t("atp_info_subtitle0")}</p>
                </div>
            </div>
            <div className='ppl-exp'>
                <div className='exp-img-con'>
                    <img src={plane} alt="uçak" />
                </div>
                <div className='exp-main-con'>
                    <h1>{t("atp_info_subheader1")}</h1>
                    <p>{t("atp_info_subtitle1")}</p>
                </div>
            </div>
            <div className='ppl-exp'>
                <div className='exp-img-con'>
                    <img src={plane} alt="uçak" />
                </div>
                <div className='exp-main-con'>
                    <h1>{t("atp_info_subheader2")}</h1>
                    <p>
                        {t("atp_info_subtitle2_1")} <br />
                        {t("atp_info_subtitle2_2")} <br />
                        {t("atp_info_subtitle2_3")} <br />
                        {t("atp_info_subtitle2_4")} <br />
                    </p>
                </div>
            </div>
            <WhatsAppButton/>
            <Footer />
        </div>
    )
}

export default Atp