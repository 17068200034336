import React from 'react'
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import WhatsAppButton from '../../Components/WhatsAppButton';

import blog7img from '../../assets/images/blogseven7.jpeg'

import '../../App.css'
import '../../style.scss'

function Blog7() {

    const { t } = useTranslation();

    return (
        <div className='blogs'>
            <Header />
            <motion.div className='blogs-main'
                initial={{
                    y: 100,
                    opacity: 0.2,

                }}
                whileInView={{
                    y: 1,
                    opacity: 1,
                }}
                transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 10,
                    duration: 2
                }}
            >
                <div className='blogs-head'>
                    <h1>{t("Blogseven_header")}</h1>
                </div>
                <div className='container-blog'>
                <img src={blog7img} alt="vafa blog" />
                </div>
                <div className='blogs-exp'><p>
                    {t("Blogseven_title_1")} <br /> <br />
                    {t("Blogseven_title_2")} <br /> <br />
                    {t("Blogseven_title_3")} <br /> <br />
                    {t("Blogseven_title_4")} <br /> <br />
                    {t("Blogseven_title_5")} <br /> <br />
                    {t("Blogseven_title_6")} <br /> <br />
                    {t("Blogseven_title_7")} <br /> <br />
                    {t("Blogseven_title_8")} <br /> <br />
                    {t("Blogseven_title_9")} <br /> <br />
                </p></div>
            </motion.div>
            <WhatsAppButton />
            <Footer />
        </div>
    )
}

export default Blog7