import React from 'react'
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import WhatsAppButton from '../../Components/WhatsAppButton';

import blogthreeimg from '../../assets/images/blogthreeimg.png'

import '../../App.css'
import '../../style.scss'
function Blog3() {

    const { t } = useTranslation();

    return (
        <div className='blogs'>
            <Header />
            <motion.div className='blogs-main'
                initial={{
                    y: 100,
                    opacity: 0.2,

                }}
                whileInView={{
                    y: 1,
                    opacity: 1,
                }}
                transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 10,
                    duration: 2
                }}
            >
                <div className='blogs-head'>
                    <h1>{t("Blogthree_header")}</h1>
                </div>

                <div className='container-blog'>
                <img src={blogthreeimg} alt="vafa blog" />
                </div>
                
                <div className='blogs-exp'><p>
                    {t("Blogthree_title_1")} <br /> <br />
                    {t("Blogthree_title_2")} <br /> <br />
                    {t("Blogthree_title_3")} <br /> <br />
                    {t("Blogthree_title_4")} <br /> <br />
                    {t("Blogthree_title_5")} <br /> <br />
                    {t("Blogthree_title_6")} <br /> <br />
                    {t("Blogthree_title_7")} <br /> <br />
                    {t("Blogthree_title_8")} <br /> <br />
                    {t("Blogthree_title_9")} <br /> <br />
                    {t("Blogthree_title_10")} <br /> <br />
                    {t("Blogthree_title_11")} <br /> <br />
                    {t("Blogthree_title_12")} <br /> <br />
                    {t("Blogthree_title_13")} <br /> <br />
                    {t("Blogthree_title_14")} <br /> <br />
                    {t("Blogthree_title_15")} <br /> <br />
                    {t("Blogthree_title_16")} <br /> <br />
                    {t("Blogthree_title_17")} <br /> <br />
                </p></div>
            </motion.div>
            <WhatsAppButton />
            <Footer />
        </div>
    )
}

export default Blog3