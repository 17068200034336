import React, { useState, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';


import tr_R1 from '../assets/slider/tr_banner1.jpg';
import tr_R2 from '../assets/slider/tr_banner2.jpg';
import tr_R3 from '../assets/slider/tr_banner3.jpg';

import tr_R5 from '../assets/slider/tr_banner5.jpg';

import en_R1 from '../assets/slider/en_banner1.jpg';
import en_R2 from '../assets/slider/en_banner2.jpg';
import en_R3 from '../assets/slider/en_banner3.jpg';

import en_R5 from '../assets/slider/en_banner5.jpg';

import de_R1 from '../assets/slider/de_banner1.jpg';
import de_R2 from '../assets/slider/de_banner2.jpg';
import de_R3 from '../assets/slider/de_banner3.jpg';

import de_R5 from '../assets/slider/de_banner5.jpg';

import ru_R1 from '../assets/slider/ru_banner1.jpg';
import ru_R2 from '../assets/slider/ru_banner2.jpg';
import ru_R3 from '../assets/slider/ru_banner3.jpg';

import ru_R5 from '../assets/slider/ru_banner5.jpg';

import ar_R1 from '../assets/slider/ar_banner1.jpg';
import ar_R2 from '../assets/slider/ar_banner2.jpg';
import ar_R3 from '../assets/slider/ar_banner3.jpg';

import ar_R5 from '../assets/slider/ar_banner5.jpg';

const App = () => {
  const { t, i18n } = useTranslation();
  const [current, setCurrent] = useState(0);
  const [isNext, setIsNext] = useState(true);

  const items = {
    tr: [ tr_R1, tr_R2, tr_R3, tr_R5],
    en: [ en_R1, en_R2, en_R3, en_R5],
    de: [ de_R1, de_R2, de_R3, de_R5],
    ru: [ ru_R1, ru_R2, ru_R3, ru_R5],
    ar: [ ar_R1, ar_R2, ar_R3, ar_R5],
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      let index = current + 1;
      if (index >= items[i18n.language].length) index = 0;
      setCurrent(index);
      setIsNext(true);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [current, i18n.language]);

  const handlerPrev = () => {
    let index = current - 1;
    if (index < 0) index = items[i18n.language].length - 1;
    setCurrent(index);
    setIsNext(false);
  };

  const handlerNext = () => {
    let index = current + 1;
    if (index >= items[i18n.language].length) index = 0;
    setCurrent(index);
    setIsNext(true);
  };

  const goToHistoryClick = (curIndex, index) => {
    setIsNext(curIndex < index);
    setCurrent(index);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setCurrent(0); // Dil değiştiğinde slide'ı sıfırla
  }, [i18n.language]);

  return (
    <div className="app">
      <div className="carousel">
        <TransitionGroup>
          <CSSTransition key={current} classNames={isNext ? 'enter-next' : 'enter-prev'} timeout={500} unmountOnExit>
            <div className="carousel_slide">
              <img src={items[i18n.language][current]} alt="Vafa Slider" />
              <button className="carousel_control carousel_control__prev" onClick={handlerPrev}><span></span></button>
              <button className="carousel_control carousel_control__next" onClick={handlerNext}><span></span></button>
            </div>
          </CSSTransition>
        </TransitionGroup>

        <div className="carousel_history">
          <History current={current} items={items[i18n.language]} changeSlide={goToHistoryClick} />
        </div>
      </div>
    </div>
  );
};

class History extends React.Component {
  render() {
    const { current, items, changeSlide } = this.props;
    const historyItems = items.map((el, index) => (
      <li key={index}>
        <button className={index === current ? 'active' : ''} onClick={() => changeSlide(current, index)}></button>
      </li>
    ));

    return <ul>{historyItems}</ul>;
  }
}

export default App;
