import React from 'react';
import { useTranslation } from 'react-i18next';

import "../../App.css";
import "../../style.scss";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import WhatsAppButton from '../../Components/WhatsAppButton';

import plane from "../../assets/images/small-plane.png";

function Pic() {

    const { t } = useTranslation();

    return (
        <div className='main-con-ppl'>
            <Header />
            <h1 className='ppl-head'>{t("pic_info_header")}</h1>
            <div className='ppl-exp'>
                <div className='exp-img-con'>
                    <img src={plane} alt="uçak" />
                </div>
                <div className='exp-main-con'>
                    <br /><br />
                    <p>{t("pic_info_subtitle0")}</p>
                </div>
            </div>
            <div className='ppl-exp'>
                <div className='exp-img-con'>
                    <img src={plane} alt="uçak" />
                </div>
                <div className='exp-main-con'>
                    <h1>{t("pic_info_subheader1")}</h1>
                    <p>{t("pic_info_subtitle1")}</p>
                </div>
            </div>
            <div className='ppl-exp'>
                <div className='exp-img-con'>
                    <img src={plane} alt="uçak" />
                </div>
                <div className='exp-main-con'>
                    <h1>{t("pic_info_subheader2")}</h1>
                    <p>
                        {t("pic_info_subtitle2_1")} <br />
                        {t("pic_info_subtitle2_2")} <br />
                        {t("pic_info_subtitle2_3")} <br />
                        {t("pic_info_subtitle2_4")} <br />
                    </p>
                </div>
            </div>
            <div className='ppl-exp'>
                <div className='exp-img-con'>
                    <img src={plane} alt="uçak" />
                </div>
                <div className='exp-main-con'>
                    <h1>{t("pic_info_subheader3")}</h1>
                    <p>{t("pic_info_subtitle3")}</p>
                </div>
            </div>
            <div className='ppl-exp'>
                <div className='exp-img-con'>
                    <img src={plane} alt="uçak" />
                </div>
                <div className='exp-main-con'>
                    <h1>{t("pic_info_subheader4")}</h1>
                    <p>
                        {t("pic_info_subtitle4_1")} <br/>
                        {t("pic_info_subtitle4_2")} <br/>
                        {t("pic_info_subtitle4_3")} <br/>
                        {t("pic_info_subtitle4_4")} <br/>
                        {t("pic_info_subtitle4_5")} <br/>
                        </p>
                </div>
            </div>
            <WhatsAppButton />
            <Footer />
        </div>
    )
}

export default Pic