import React, { useState } from "react";
import vafaLogo from "../assets/images/vafaLogo.png";
import camLogo from "../assets/images/camLogo.png";
import vizyonlogo from "../assets/Logos/vizyonlogo.png"
import icon from "../assets/Logos/line-md_menu-to-close-transition.svg"
import close from "../assets/Logos/iconamoon_close-duotone.svg"
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import i18next from "i18next";







const languages = [
  {
    code: "tr",
    name: "TR",
  },
  {
    code: "en",
    name: "EN",
  },
  {
    code: "de",
    name: "DE",
  },
  {
    code: "ru",
    name: "RU",
  },
  {
    code: "ar",
    name: "AR",
  },
]

function Header() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const { t } = useTranslation();


  return (
    <div className="nav-bar">
      <div className="nav-bar-desktop">
        <a href="/">   <img className="vafa-logo" src={vafaLogo} alt="Vafa Logo" /></a>
        <nav className="nav-bar-items" >

          {/*
          <HashLink to={"/#academi"}>{t('academy')}</HashLink>
          <HashLink to="/#filo">{t("filo")}</HashLink>
        */}

          <Link to="/graduates">{t("mezun")}</Link>
          <Link to="/bloghome">{t("blog")}</Link>
          <Link to="/galeri">{t("galeri")}</Link>
          <HashLink to={"/Contact"}>{t("basvuru")}</HashLink>
          {/* <Link href="">İletişim</Link> */}
        </nav>
        <div className="nav-bar-menu-button" onClick={handleClick}>
          <img src={click ? close : icon} alt="icon" />
        </div>
        <div className="navbar-mobile-logo">
          <select defaultValue={i18next.language} className="nav-bar-dropdown" onChange={(e) => i18next.changeLanguage(e.target.value)}>
            {languages.map(({ code, name, }) => (
              <option value={code} key={code}>
                {name}
              </option>
            ))}
          </select>
          <Link to="https://www.vizyonhavacilik.com/" target="_blank" rel="noreferrer">
            <img className="header-vizyon-logo" src={vizyonlogo} alt="Vizyonhavacılık Logo" />
          </Link>
          <Link to="https://www.camgrup.org/" target="_blank" rel="noreferrer" >
            {" "}
            <img className="header-cam-logo" width={30} src={camLogo} alt="Çam Grup Logo" />
          </Link>
        </div>
      </div>
      <div className="nav-bar-mobile">
        <nav className={click ? "nav-bar-mobile-items" : "nav-bar-mobile-items-close"}  >
          <div className="nav-bar-dropdown-main-mobile">
            <select defaultValue={i18next.language} className="nav-bar-dropdown-mobile" onChange={(e) => i18next.changeLanguage(e.target.value)}>
              {languages.map(({ code, name, }) => (
                <option value={code} key={code}>
                  {name}
                </option>
              ))}
            </select>
          </div>

          {/*
          <HashLink onClick={handleClick} to={"/#academi"}>{t("academy")}</HashLink>
          <HashLink onClick={handleClick} to="/#filo">{t("filo")}</HashLink>
          */}

          <Link onClick={handleClick} to="/graduates">{t("mezun")}</Link>
          <Link onClick={handleClick} to="/bloghome">{t("blog")}</Link>
          <Link onClick={handleClick} to="/galeri">{t("galeri")}</Link>
          <HashLink onClick={handleClick} to={"/Contact"}>{t("basvuru")}</HashLink>
        </nav></div>
    </div>
  );
}
export default Header;
